<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <!-- <v-btn :to="{ name: 'PartnerIndex' }" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn> -->
      <v-toolbar-title v-if="currentUser"
        >Партнерская программа</v-toolbar-title
      >
      <template v-slot:extension>
        <v-tabs v-model="balanceTab" centered grow>
          <v-tab to="/partner">Вывести</v-tab>
          <v-tab to="/partner/history">История</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-container v-if="!loadPage && currentUser" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card class="elevation-0">
            <router-link
              :to="{
                name: 'Page',
                params: {
                  slug: 'partnerskaya-programma-pravila',
                },
              }"
            >
              Партнерская программа Правила
            </router-link>
            <!-- partnerskaya-programma-pravila -->
            <div class="mb-8">
              <v-select
                v-if="books.length > 0"
                v-model="bookSlug"
                :items="books"
                item-text="title"
                item-value="slug"
                label="Выбирите вашу книгу"
                hint="Выбирите книгу, чтобы сформировать партнерскую ссылку"
                persistent-hint
                class="mb-4 mt-4"
              ></v-select>

              Ваша партнерская ссылка:<br /><a
                @click="copyUrl"
                style="color: blue"
                >{{ partnerLink }}</a
              >
              <br />
              <v-btn dark color="primary" small @click="copyUrl"
                >Копировать</v-btn
              >
              <div v-if="partnerLink2" class="mt-4">
                Ваша партнерская ссылка на чтение книги:<br /><a
                  @click="copyUrl2"
                  style="color: blue"
                  >{{ partnerLink2 }}</a
                >
                <br />
                <v-btn dark color="primary" small @click="copyUrl2"
                  >Копировать</v-btn
                >
              </div>
            </div>

            <div class="mb-4">
              Баланс: {{ currentUser.pbalance }} р. ({{ currentUser.preserve }}
              р.)
            </div>
            <v-form v-model="validMinus" ref="formMinus">
              <!-- <v-subheader class="px-0"
                    >Коммиссия {{ out_commission }}%. Вы получите
                    {{ outSumm }} руб.</v-subheader
                  > -->
              <v-text-field
                v-model="summMinus"
                name="inputSummMinus"
                label="Сумма"
                required
                :rules="balanceMinusRules"
              ></v-text-field>

              <v-btn dark color="primary" @click="balanceMinus">Вывести</v-btn>
            </v-form>
          </v-card>

          <v-subheader class="mt-4">История операций</v-subheader>
          <v-row v-if="!payouts || payouts.length == 0" align="center">
            <no-content route-name title="Данных нет"></no-content>
          </v-row>
          <v-data-table
            v-else
            :headers="headersTable"
            :items="payouts"
            :items-per-page="15"
            class
            item-key="id_table"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  //mixins: [snackbarError],
  data: () => ({
    validPlus: true,
    validMinus: true,
    balanceTab: 0,
    summPlus: 0,
    summMinus: 0,
    headersTable: [
      {
        text: "Дата",
        align: "start",
        value: "date",
      },
      { text: "Сумма", value: "summ" },
      { text: "Статус", value: "status_formated" },
    ],
    requiredRules: [(v) => !!v || "Поле обязательно"],
    titleRules: [
      (v) => !!v || "Поле обязательно",
      (v) => v.length >= 3 || "Минимум 3 символа",
    ],
    // balanceRules: [
    //   // v => !!v || 'Поле обязательно',
    //   (v) => !!v === v >= 100 || "Минимум 100 р",
    // ],
    commission: 0, //Комиссия в процентах
    bookSlug: null,
  }),
  methods: {
    validatePlus() {
      return this.$refs.formPlus.validate();
    },
    validateMinus() {
      return this.$refs.formMinus.validate();
    },
    // balancePlus() {
    //   if (!this.validatePlus()) return;
    //   let app = this;
    //   this.axios
    //     .post("/add-in-transaction", {
    //       summ: app.summPlus,
    //     })
    //     .then(function (response) {
    //       if (response.data.success == 1) {
    //         if (response.data.linkPay) {
    //           // window.open(response.data.linkPay, "_blank");
    //           window.location.href = response.data.linkPay;
    //         }

    //         if (response.data.payouts) {
    //           app.$store.commit(
    //             "cabinet_balance/GET_TRANSACTIONS",
    //             response.data.payouts
    //           );
    //         }

    //         app.summPlus = 0;
    //         app.validPlus = true;
    //       }
    //     });
    // },
    balanceMinus() {
      if (!this.validateMinus()) return;
      let app = this;
      this.$store
        .dispatch("cabinet_partner/balanceMinus", {
          summ: app.summMinus,
        })
        .then(() => {
          app.summMinus = 0;
          app.validMinus = true;
        });
    },
    getPayouts() {
      // let app = this;
      this.$store.dispatch("cabinet_partner/getPayouts");
    },
    copyUrl() {
      window.navigator.clipboard.writeText(this.partnerLink);
    },
    copyUrl2() {
      window.navigator.clipboard.writeText(this.partnerLink2);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      payouts: "cabinet_partner/payouts",
      books: "cabinet_partner/books",
      // out_commission: "cabinet_partner/out_commission",
    }),
    balanceMinusRules() {
      const rules = [];

      if (this.currentUser) {
        const rule = (v) => (v && v >= 2000) || "Минимум 2000 р";
        const rule2 = (v) =>
          (v && v <= this.currentUser.pbalance) || "Недостаточно средств";
        rules.push(rule);
        rules.push(rule2);
      }

      return rules;
    },
    outSumm() {
      if (this.out_commission && this.summMinus) {
        let comm = Math.ceil((this.summMinus * this.out_commission) / 100);
        return this.summMinus - comm;
      }

      return this.summMinus;
    },
    partnerLink() {
      if (this.currentUser) {
        let path = process.env.VUE_APP_URL + "/register";

        if (this.bookSlug) {
          path = process.env.VUE_APP_URL + "/book/" + this.bookSlug;
        }
        let link = path + "?p=" + this.currentUser.id;
        return link;
      }
      return "";
    },
    partnerLink2() {
      let link = null;
      if (this.currentUser) {
        let path = null;

        if (this.bookSlug) {
          path = process.env.VUE_APP_URL + "/reader/" + this.bookSlug;
          link = path + "?p=" + this.currentUser.id;
        }
      }
      return link;
    },
  },
  mounted() {
    this.getPayouts();
  },
};
</script>
